jQuery(document).ready(function($){

  $('.scroll-link').click(function(event) {
      event.preventDefault();

      var target = $(this.hash);
      target = target.length ? target : $(this.hash);
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 400);
      }
  });

});
